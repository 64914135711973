<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div>
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'title')" type="text" placeholder="Search">
                    </div>
                </div>
                <div>
                    <div class="uk-inline">
                        <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="goToCreateCategory">Create</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div>
                <table class="uk-table uk-table-small uk-table-divider">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Status</th>
                            <th>Title</th>
                            <th>Seo Url</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="jobs.docs.length>0">
                        <tr v-for="(job, i) in jobs.docs" :key="i">
                            <td>{{job.id}}</td>
                            <td><div class="label" :style="`background-color: ${job.is_active ? '#00cc00' : '#ba1818'}`">{{job.is_active ? 'Active' : 'Inactive'}}</div></td>
                            <td>{{job.title}}</td>
                            <td>{{job.seo_url}}</td>
                            <td>
                                <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-primary" @click="changeStatus(job.id, 'true')">Activate</button>
                                <br/>
                                <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-danger" @click="changeStatus(job.id, 'false')">Deactivate</button>
                                <br/>
                                <button style="width:100px; cursor:pointer; height:30px" class="uk-button-default" @click="showEditModal(job)">Edit</button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="jobs.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="edit-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideEditModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Edit</h2>
                </div>
                <div class="uk-modal-body">
                    <label class="uk-form-label">Title</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="job_detail.title"
                            placeholder="input title">
                    </div>
                    <label class="uk-form-label">Content</label>
                    <div class="uk-width-1-1 uk-inline">
                        <textarea
                            class="uk-input"
                            v-model="job_detail.content"
                            placeholder="input content"
                        ></textarea>
                    </div>
                    <label class="uk-form-label">Meta Title</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="job_detail.meta_title"
                            placeholder="input meta title"
                        >
                    </div>
                    <label class="uk-form-label">Meta Description</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="job_detail.meta_desc"
                            placeholder="input meta desc"
                        >
                    </div>
                    <label class="uk-form-label">Meta Keywords</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="job_detail.meta_keywords"
                            placeholder="input meta keyword"
                        >
                    </div>
                    <label class="uk-form-label">Seo Url</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="job_detail.seo_url"
                            placeholder="input seo url"
                        >
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default" type="button" @click="saveEditModal">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/utils/api';
import { API_HOSPITALITY } from '@/utils/api-url';
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data() {
        return {
            category_seo_url: '',
            meta: {
                title: '',
                limit: 100,
                page: 1,
            },
            job_detail: {
                title: '',
                content: '',
                meta_title: '',
                meta_desc: '',
                meta_keywords: '',
                seo_url: ''
            }
        };
    },
    components: {
        Pagination,
        EmptyTable
    },
    computed : {
        ...mapGetters({
            jobs: 'hospitality/jobs',
        })
    },
    watch: {
        meta() {
            this.setCategoryMeta();
        },
    },
    mounted() {
        this.category_seo_url = this.$route.params.category_seo_url;
        this.setCategoryMeta();
    },
    methods: {
        ...mapActions({
            getJob: 'hospitality/getJob'
        }),
        setCategoryMeta() {
            this.getJob({seo_url: this.category_seo_url, data: this.meta});
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
            this.getJob({seo_url: this.category_seo_url, data: this.meta});
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async showEditModal(job) {
            this.job_detail = job;
            await window.UIkit.modal('#edit-modal').show();
        },
        async hideEditModal() {
            await window.UIkit.modal('#edit-modal').hide();
        },
        async saveEditModal() {
            try {
                await api.apiPutAuth(API_HOSPITALITY.UPDATE_JOB(this.job_detail.id), this.job_detail);
                notificationSuccess('Job updated');
                this.getJob({seo_url: this.category_seo_url, data: this.meta});
                await window.UIkit.modal('#edit-modal').hide();
            } catch (err) {
                notificationDanger(err);
            }
        },
        async changeStatus(id, status) {
            try {
                await api.apiPatchAuth(API_HOSPITALITY.CHANGE_JOB_STATUS(id, status));
                notificationSuccess('Status changed !');
                this.getJob({seo_url: this.category_seo_url, data: this.meta});
            } catch (err) {
                notificationDanger(err);
            }
        },
        goToCreateCategory() {
            this.$router.push('/admin/hospitality/job/create');
        }
    }
};
</script>
